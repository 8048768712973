import React from 'react'
import { graphql, Link, PageProps } from 'gatsby'

import Layout from '../main-components/Layout'
import SEO from '../components/SEO'
import { ISite } from '../types'

const NotFoundPage = ({ data }: PageProps<{ site: ISite }>) => {
  return (
    <Layout>
      <SEO title="Page Not Found" />
      <h1 className="text-2xl mb-2 font-bold">Page Not Found</h1>
      <p className="mb-4">
        This route doesn't exist... please find another route from{' '}
        <Link to="/" className="underline">
          home
        </Link>
        .
      </p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
